import React, {useEffect, useState} from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container, Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent, TabPane
} from "reactstrap"
import { Tooltip } from 'react-tooltip'

import default_avatar from "../../assets/images/users/default_avatar.webp"
import profile_header_bg from "../../assets/images/profile-header-bg.webp"
import classnames from "classnames"
import {changeAvatar, getProfile, updateProfile} from "../../api/account"
import Flatpickr from "react-flatpickr"
import {toast} from "react-toastify"
import ChangePasswordForm from "./ChangePasswordForm"
import Documents from "./Documents"
import FeatherIcon from "feather-icons-react"
import TwoStepVerification from "./Components/TwoStepVerification"
import {getProfileInfo} from "../../slices/auth/profile/thunk"
import {useDispatch} from "react-redux"
import {selectListData} from "../../api/general"
import Select from "react-select"
import {useTranslation} from "react-i18next"

const Profile = () => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('personal_details')
  const [birthday, setBirthday] = useState('')
  const [profileData, setProfileData] = useState([])
  const [countriesOptions, setCountriesOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const { t } = useTranslation()

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const getProfileData = () => {
    getProfile().then(r => {
      setProfileData(r)
      getCountriesData(r.customer_country)
    })
  }

  const getCountriesData = (defaultCountry) => {
    selectListData('countries').then(r => {
      setCountriesOptions(r)
      if (defaultCountry) {
        setSelectedCountry(r.find(i => i.label === defaultCountry))
      }
    })
  }

  const changeAvatarFormHandler = e => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('avatar_img', e.target.files[0])

    changeAvatar(formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        getProfileData()
        dispatch(getProfileInfo())
      } else {
        toast.error(r.message)
      }
    }).catch(error => {
      toast.error(error)
    })
  }

  const submitHandler = e => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    const formData = new FormData(e.target)
    const data = Object.fromEntries( formData.entries() )

    if (birthday) {
      data.birthday = birthday.toDateString()
    }

    updateProfile(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        getProfileData()
        dispatch(getProfileInfo())
      } else {
        if (r.errors) {
          Object.values(r.errors).forEach(i => i.forEach(j => toast.error(j)))
        } else {
          toast.error(r.message)
        }
      }
    }).catch(error => {
      toast.error(error)
    }).finally(() => btn.disabled = false)
  }

  useEffect(() => {
    if (profileData.birthday) {
      setBirthday((new Date(profileData.birthday)))
    }
  }, [profileData])

  useEffect(() => {
    getProfileData()
    // getCountriesData()
  }, [])

  document.title = t("Profile") + ' | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={profile_header_bg} className="profile-wid-img" alt="" />
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit"></div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={profileData.avatar_url ? profileData.avatar_url : default_avatar}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          name="avatar_img"
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                          onChange={changeAvatarFormHandler}
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                              <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">{profileData?.first_name} {profileData?.last_name}</h5>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">{t("Your Profile Progress")}</h5>
                    </div>
                    <div className="flex-shrink-0">
                      <a
                        data-tooltip-id="progress-tooltip"
                        data-tooltip-content={t("Fill more information about you to increace the progress!")}
                      >
                        <FeatherIcon icon="info" className="text-primary"/>
                      </a>
                      <Tooltip id="progress-tooltip"/>
                    </div>
                  </div>
                  <div className="progress animated-progress custom-progress progress-label">
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ "width": `${profileData.progress_pct}%` }}
                      aria-valuenow={profileData.progress_pct}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="label">{profileData.progress_pct}%</div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <TwoStepVerification
                twoFactorAuthIsEnabled={profileData.two_factor_auth_is_enabled}
                toggleSecurityStateHandler={getProfileData}
              />
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'personal_details' })}
                        onClick={() => {
                          tabChange('personal_details')
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        {t("Personal Details")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === 'change_password' })}
                        onClick={() => {
                          tabChange('change_password')
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        {t("Change Password")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === 'documents' })}
                        onClick={() => {
                          tabChange('documents')
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        {t("Documents")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="personal_details">
                      <Form onSubmit={submitHandler}>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="firstnameInput" className="form-label">{t("First Name")}</Label>
                              <Input
                                name="first_name"
                                type="text"
                                className="form-control"
                                id="firstnameInput"
                                placeholder={t("Enter your firstname")}
                                defaultValue={profileData?.first_name}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="lastnameInput" className="form-label">{t("Last Name")}</Label>
                              <Input
                                name="last_name"
                                type="text"
                                className="form-control"
                                id="lastnameInput"
                                placeholder={t("Enter your lastname")}
                                defaultValue={profileData?.last_name}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="phonenumberInput" className="form-label">{t("Phone Number")}</Label>
                              <Input
                                name="full-phone"
                                type="text"
                                className="form-control"
                                id="phonenumberInput"
                                placeholder={t("Enter your phone number")}
                                defaultValue={profileData?.phone}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="emailInput" className="form-label">{t("Email Address")}</Label>
                              <Input
                                name="email"
                                type="email"
                                className="form-control"
                                id="emailInput"
                                placeholder={t("Enter your email")}
                                defaultValue={profileData?.email}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="cityInput" className="form-label">{t("City")}</Label>
                              <Input
                                name="city"
                                type="text"
                                className="form-control"
                                id="cityInput"
                                placeholder={t("City")}
                                defaultValue={profileData?.city}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="inputCountry" className="form-label">{t("Country")}</Label>
                              <Select
                                placeholder="Country"
                                id="inputCountry"
                                name="country"
                                options={countriesOptions}
                                value={selectedCountry}
                                onChange={(selected) => {
                                  setSelectedCountry(selected)
                                }}
                                required={true}
                              >
                                <option value="">{t("Select country")}</option>
                              </Select>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label htmlFor="cantonInput" className="form-label">{t("Canton")}</Label>
                              <Input
                                name="canton"
                                type="text"
                                className="form-control"
                                id="cantonInput"
                                placeholder={t("Canton")}
                                defaultValue={profileData?.state}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3">
                              <Label htmlFor="addressInput" className="form-label">{t("Address")}</Label>
                              <Input
                                name="address"
                                type="text"
                                className="form-control"
                                id="addressInput"
                                placeholder={t("Address")}
                                defaultValue={profileData?.address}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="postalcodeInput" className="form-label">{t("Postal Code")}</Label>
                              <Input
                                name="postal_code"
                                type="text"
                                className="form-control"
                                id="postalcodeInput"
                                placeholder={t("Enter postal code")}
                                defaultValue={profileData?.postal_code}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="birthdayInput" className="form-label">{t("Birthdate")}</Label>
                              <Flatpickr
                                className="form-control"
                                options={{
                                  dateFormat: "d M, Y"
                                }}
                                onChange={e => setBirthday(e[0])}
                                value={birthday}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="mb-3 pb-2">
                              <Label htmlFor="exampleFormControlTextarea" className="form-label">{t("Details")}</Label>
                              <textarea
                                name="details"
                                className="form-control"
                                id="exampleFormControlTextarea"
                                rows="3"
                                defaultValue={profileData?.details}
                              >
                              </textarea>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button type="submit" className="btn btn-primary">{t("Update")}</button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tabId="change_password">
                      <ChangePasswordForm />
                    </TabPane>

                    <TabPane tabId="documents">
                      <Documents activeTab={activeTab} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile
